import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import * as React from "react";
import OpImage from "../images/Management.png";

const Landing = () => (
	<Box align="center">
		<Flex
			bg="gray.200"
			maxW="5xl"
			rounded="xl"
			m="12"
			p="6"
			textAlign="left"
			direction={{ base: "column", md: "row" }}
			justify="normal"
		>
			<Box w="full" maxW="lg" p="4" rounded="xl">
				<Text
					align="left"
					fontFamily="PT Serif"
					fontSize={{ base: "xl", sm: "2xl", md: "3xl" }}
				>
					Allison is a Program Manager in the Health Research Field
					living in <Text as="s">Boston</Text> New York City
				</Text>
				<Button
					mt="8"
					as="a"
					href="mailto:aamcbride20@gmail.com"
					size="lg"
					colorScheme="blue"
					fontWeight="bold"
				>
					Contact Me
				</Button>
			</Box>
			<Image maxH={{ base: "sm", lg: "lg" }} fit="scale-down" src={OpImage} />
		</Flex>
	</Box>
);

export default Landing;
