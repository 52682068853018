import React from "react";
import {
	Box,
	Text,
	ListItem,
	UnorderedList,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react";
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator,
} from "@material-ui/lab";

const Experience = () => {
	const isMobile = useBreakpointValue({ base: false, md: true });

	return isMobile ? (
		<Box
			bg="gray.200"
			maxW="5xl"
			rounded="xl"
			mx="12"
			p="6"
			textAlign="left"
			justify="normal"
		>
			<Text fontWeight="semibold" fontFamily="PT Serif" fontSize="3xl">
				My Journey
			</Text>
			<Timeline align="alternate">
				<TimelineItem>
					<TimelineOppositeContent>
						<Text fontFamily='PT Serif'>December 2021 - Current</Text>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot />
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						<Box
							fontFamily='PT Serif'
							bg='gray.50'
							boxShadow='dark-lg'
							rounded='lg'
							p='4'
						>
							<Text fontWeight='semibold' fontSize='lg'>
								Program Manager, Overdose Prevention
							</Text>
						</Box>
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent>
						<Text fontFamily="PT Serif">April 2021 - December 2021</Text>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot />
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						<Box
							fontFamily="PT Serif"
							bg="gray.50"
							boxShadow="dark-lg"
							rounded="lg"
							p="4"
						>
							<Text fontWeight="semibold" fontSize="lg">
								Director of Strategic Operations
							</Text>
							<UnorderedList>
								<ListItem>
									Design and conduct operations for a team of 45 staff and
									volunteers.
								</ListItem>
								<ListItem>
									Survey and Deploy software tools for program and individual
									project success.
								</ListItem>
								<ListItem>
									Fundraise over $200,000 for criminal justice reform projects.
								</ListItem>
								<ListItem>
									Provide research, legal tools, advocacy materials, and
									technical assistance to over 130 community based
									organizations, policy makers, public defenders, DA offices.
								</ListItem>
							</UnorderedList>
						</Box>
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent>
						<Text fontFamily="PT Serif">July 2020 - March 2021</Text>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot />
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						<Box
							fontFamily="PT Serif"
							align="left"
							bg="gray.50"
							boxShadow="dark-lg"
							rounded="lg"
							p="4"
						>
							<Text fontWeight="semibold" fontSize="lg">
								Assoc. Director of Strategic Operations
							</Text>
							<UnorderedList>
								<ListItem>
									Managed, mentored, and provided constructive feedback to 8
									project leads.
								</ListItem>
								<ListItem>
									Introduced and implemented agile concepts for existing and new
									projects.
								</ListItem>
								<ListItem>
									Expanded operations and Increaserd headcount by 180% within 6
									months of promotion
								</ListItem>
								<ListItem>
									Drafted grant proposals, researched funders, and reported
									progress across grant cycles
								</ListItem>
							</UnorderedList>
						</Box>
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent>
						<Box
							fontFamily="PT Serif"
							bg="gray.50"
							boxShadow="dark-lg"
							rounded="lg"
							p="4"
						>
							<Text fontWeight="semibold" fontSize="lg">
								MPH @ BU School of Public Health
							</Text>
							<Text>Class of 2020</Text>
						</Box>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot />
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						<Box
							fontFamily="PT Serif"
							bg="gray.50"
							boxShadow="dark-lg"
							rounded="lg"
							p="4"
						>
							<Text fontWeight="semibold" fontSize="lg">
								Research Manager
							</Text>
							<UnorderedList>
								<ListItem>
									Led a team of 10 in producing drug overdose research and
									communication materials.
								</ListItem>
								<ListItem>
									Established a design protocol for research and internal
									administrative databases.
								</ListItem>
								<ListItem>
									Led the development of the organization's 1,500 follower
									Instagram presence.
								</ListItem>
								<ListItem>
									Assembled crowdsourcing systems for over 50 public health
									professionals and academics.
								</ListItem>
							</UnorderedList>
						</Box>
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineOppositeContent>
						<Text fontFamily="PT Serif">Sep 2018 - March 2020</Text>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot />
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						<Box
							fontFamily="PT Serif"
							align="left"
							bg="gray.50"
							boxShadow="dark-lg"
							rounded="lg"
							p="4"
						>
							<Text fontWeight="semibold" fontSize="lg">
								Harm Reduction Advocate
							</Text>
							<UnorderedList>
								<ListItem>
									Educated clients on safe consumption and overdose prevention
									practices.
								</ListItem>
								<ListItem>
									Improved clients' access to healthcare, housing, recovery, and
									legal services.
								</ListItem>
								<ListItem>
									Evaluated development of a womxn’s drop-in for harm reduction
									and supportive services.
								</ListItem>
							</UnorderedList>
						</Box>
					</TimelineContent>
				</TimelineItem>
			</Timeline>
		</Box>
	) : (
		<Box
			bg="gray.200"
			maxW="5xl"
			rounded="xl"
			mx="12"
			p="6"
			textAlign="left"
			justify="normal"
		>
			<Text pb="4" fontWeight="semibold" fontFamily="PT Serif" fontSize="3xl">
				My Journey
			</Text>
			<VStack spacing="4">
				<Box
					fontFamily="PT Serif"
					bg="gray.50"
					boxShadow="md"
					rounded="lg"
					p="4"
				>
					<Text fontWeight="semibold" fontSize="lg">
						Director of Strategic Operations
					</Text>
					<Text>March 2021 - Current</Text>
					<UnorderedList>
						<ListItem>
							Design and conduct operations for a team of 45 staff and
							volunteers
						</ListItem>
						<ListItem>
							Survey and Deploy software tools for program and individual
							project success
						</ListItem>
						<ListItem>
							Fundraise over $200,000 for criminal justice reform projects
						</ListItem>
						<ListItem>
							Provide research, legal tools, advocacy materials, and technical
							assistance to over 130 community based organizations, policy
							makers, public defenders, DA offices
						</ListItem>
					</UnorderedList>
				</Box>
				<Box
					w="full"
					fontFamily="PT Serif"
					bg="gray.50"
					boxShadow="md"
					rounded="lg"
					p="4"
				>
					<Text fontWeight="semibold" fontSize="lg">
						Assoc. Director of Strategic Operations
					</Text>
					<Text>July 2020 - March 2021</Text>
					<UnorderedList>
						<ListItem>
							Managed, mentored, and provided constructive feedback to 8 project
							leads.
						</ListItem>
						<ListItem>
							Introduced and implemented agile concepts for existing and new
							projects.
						</ListItem>
						<ListItem>
							Expanded operations and Increaserd headcount by 180% within 6
							months of promotion
						</ListItem>
						<ListItem>
							Drafted grant proposals, researched funders, and reported progress
							across grant cycles
						</ListItem>
					</UnorderedList>
				</Box>
				<Box
					w="full"
					fontFamily="PT Serif"
					bg="gray.50"
					boxShadow="md"
					rounded="lg"
					p="4"
				>
					<Text fontWeight="semibold" fontSize="lg">
						MPH @ BU School of Public Health
					</Text>
					<Text>Class of 2020</Text>
				</Box>
				<Box
					fontFamily="PT Serif"
					bg="gray.50"
					boxShadow="md"
					rounded="lg"
					p="4"
				>
					<Text fontWeight="semibold" fontSize="lg">
						Research Manager
					</Text>
					<UnorderedList>
						<ListItem>
							Led a team of 15 Interns on 4 separate policy reserach projects.
						</ListItem>
						<ListItem>
							Trained incoming interns to comprehend drug policy basics, coding,
							and database Design.
						</ListItem>
						<ListItem>
							Led the development of the organizations social media presence.
						</ListItem>
					</UnorderedList>
				</Box>
				<Box
					fontFamily="PT Serif"
					align="left"
					bg="gray.50"
					boxShadow="md"
					rounded="lg"
					p="4"
				>
					<Text fontWeight="semibold" fontSize="lg">
						Harm Reduction Advocate
					</Text>
					<Text>Sep 2018 - March 2020</Text>
					<UnorderedList>
						<ListItem>
							Educated clients on safe consumption and overdose prevention
							practices.
						</ListItem>
						<ListItem>
							Improved clients' access to healthcare, housing, recovery, and
							legal services.
						</ListItem>
						<ListItem>
							Evaluated development of a womxn’s drop-in for harm reduction and
							supportive services.
						</ListItem>
					</UnorderedList>
				</Box>
			</VStack>
		</Box>
	);
};

export default Experience;
