import React from "react";
import { Helmet } from "react-helmet";
import { Box } from '@chakra-ui/react'

import NavBar from "../components/nav";
import Footer from "../components/footer";
import Landing from "../components/landing";
import Experience from "../components/experience";

function Index() {
	return (
		<main>
			<Helmet>
				<title>Allison McBride</title>
			</Helmet>
			<NavBar />
			<Box align='center'>
				<Landing />
				<Experience />
			</Box>

			<Footer />
		</main>
	);
}

export default Index;
